import request from '@/utils/request'

export function adUserCreate(data) {
    return request({
        url: '/advert/adm/create',
        method: 'post',
        data
    })
}

export function adUserGetList(data) {
    return request({
        url: '/advert/adm/getList',
        method: 'post',
        data
    })
}

export function adUserGetUpdate(data) {
    return request({
        url: '/advert/adm/update',
        method: 'post',
        data
    })
}

export function adUserAccountupdate(data) {
    return request({
        url: '/advert/adm/account/update',
        method: 'post',
        data
    })
}

export function fetchBillingList(data) {
    return request({
        url: '/advert/adm/account/billingList',
        method: 'post',
        data
    })
}


export function fetchAuditList(data) {
    return request({
        url: '/advert/advertise/auditList',
        method: 'post',
        data
    })
}

export function updataAudit(data) {
    return request({
        url: '/advert/advertise/audit',
        method: 'post',
        data
    })
}

export function uploadImg(data) {
    return request({
        url: '/advert/adm/image/upload',
        method: 'post',
        data
    })
}

export function getOne(data) {
    return request({
        url: '/advert/adm/qualification/getOne',
        method: 'post',
        data
    })
}

export function quaAdd(data) {
    return request({
        url: '/advert/adm/qualification/add',
        method: 'post',
        data
    })
}

export function quaUpdate(data) {
    return request({
        url: '/advert/adm/qualification/update',
        method: 'post',
        data
    })
}

