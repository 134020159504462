<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" >
      <el-breadcrumb-item>广告主管理</el-breadcrumb-item>
      <el-breadcrumb-item>账户信息列表</el-breadcrumb-item>
    </el-breadcrumb>
    <br />
    <el-row>

        <el-col :span="3" style="margin-right: 15px">
            <el-input clearable v-model="searchForm.account" placeholder="账户名称查询"></el-input>
        </el-col>
        <el-col :span="3" style="margin-right: 15px">
            <el-input clearable v-model="searchForm.name" placeholder="渠道名称查询"></el-input>
        </el-col>

        <!-- <el-col :span="3" style="margin-right: 30px">
            <el-select v-model="listQuery.tagId" placeholder="投放标签" clearable>
                <el-option
                    v-for="item in tagListAll"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
        </el-col> -->

        <el-col :span="3" style="margin-right: 30px">
            <el-button type="primary" @click="handlesearch">查询</el-button>
            <el-button type="primary" @click="handleCreate">新增</el-button>
        </el-col>
    </el-row>


    <!--表单 BEGIN -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">
        <el-form-item label="广告主ID" prop="id" v-if="dialogStatus === 'update'">
          <el-input v-model="temp.id" maxlength="25" placeholder="请输入" disabled/>
        </el-form-item>
        <el-form-item label="广告主类型" prop="types">
          <el-select v-model="temp.types" clearable  placeholder="请选择类型" style="width:100%">
                <el-option
                    v-for="item in manList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="所属行业" prop="entType">
          <el-cascader
            style="width:100%"
            v-model="temp.entType"
            :options="options"
            clearable
            ></el-cascader>
        </el-form-item>
        <el-form-item label="广告主地址" prop="address">
          <el-input v-model="temp.address" maxlength="25" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="联系方式" prop="onlineContact">
          <el-input v-model="temp.onlineContact" maxlength="25" placeholder="QQ/微信/邮箱"/>
        </el-form-item>
        <el-form-item label="广告主名称" prop="name">
          <el-input v-model="temp.name" maxlength="25" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="广告主账号" prop="account">
          <el-input v-model="temp.account"  maxlength="25" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="dialogStatus === 'create'">
          <el-input type="password" v-model="temp.password" show-password  placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="密码" prop="updatepassword" v-if="dialogStatus === 'update'">
          <el-input type="password" v-model="temp.updatepassword" show-password  placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="广告主手机" prop="phone">
          <el-input v-model="temp.phone"  placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="座机号码" prop="tel">
          <el-input v-model="temp.tel"  placeholder="请输入"/>
        </el-form-item>
        
        <el-form-item label="合约价格" prop="contractPrice" v-if="dialogStatus === 'update'">
          <el-input v-model="temp.contractPrice"  placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="账户状态" prop="status" v-if="dialogStatus === 'update'">
         <el-switch
            v-model="temp.status"
            @change="change(temp)"
            :active-value="1"
            :inactive-value="2"
            active-text="开"
            inactive-text="关">
          </el-switch>
        </el-form-item>

        <!-- <el-form-item label="应用" prop="appId">
          <el-select v-model="temp.appId" :disabled="dialogStatus === 'update'"  style="width:500px"   placeholder="应用">
            <el-option
                v-for="item in appOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->

        <!-- <el-form-item label="广告品类" prop="categoryId">
          <el-select v-model="temp.categoryId" value-key="id" :disabled="dialogStatus === 'update'"  style="width:500px" placeholder="广告品类">
            <el-option
                v-for="item in categoryList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="投放权重" prop="weight">
          <el-input v-model="temp.weight"  placeholder="请输入"/>
        </el-form-item> -->
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>


    <!--表单 BEGIN -->
    <el-dialog title="资质编辑" :visible.sync="dialogFormVisible4">
        <el-form ref="quAdataForm" :rules="quaRules" :model="qua" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">
          <el-form-item label="广告主ID" prop="id">
            <el-input v-model="qua.id" placeholder="请输入" disabled/>
          </el-form-item>
          <el-form-item :label="qua.types == 1 ? '营业执照' : '身份证'" prop="cert">
            <input
                ref="uploads"
                @change="uploadImg('cert', $event)"
                multiple
                type="file" 
                accept="image/*"
                name="upload"
                id="uploadFile"
                hidden
                enctype="multipart/form-data"
                >
              
                <div class="img-contaner">
                  <template v-if="cert && cert.length > 0">
                    <div class="img-warrp" v-for="(item, index) in cert" :key="index">
                      <div class="mask">
                          <div class="del" @click="deleteImg(index,'cert')"><i class="el-icon-delete" style="font-size:20px;color:#fff;position:absolute;left:0;top:0;right:0;bottom:0;margin:auto"></i></div>
                      </div>
                      <img :src="item">
                    </div>
                  </template>
                  <label for="uploadFile" class="uploa-files" v-if="cert.length < 9">
                      <i class="el-icon-plus" style="font-size:40px"></i>
                  </label>
                </div>
          </el-form-item>

          <el-form-item label="行业资质" prop="entQualification">
            <input
                ref="entuploads"
                @change="uploadImg('entQualification', $event)"
                multiple
                type="file" 
                accept="image/*"
                name="upload"
                id="entuploadFile"
                hidden
                enctype="multipart/form-data"
                >
              
                <div class="img-contaner">
                  <template v-if="entQuaUrl && entQuaUrl.length > 0">
                    <div class="img-warrp" v-for="(item, index) in entQuaUrl" :key="index">
                      <div class="mask">
                          <div class="del" @click="deleteImg(index,'entQuaUrl')"><i class="el-icon-delete" style="font-size:20px;color:#fff;position:absolute;left:0;top:0;right:0;bottom:0;margin:auto"></i></div>
                      </div>
                      <img :src="item">
                    </div>
                  </template>
                  <label for="entuploadFile" class="uploa-files" v-if="entQuaUrl.length < 9">
                      <i class="el-icon-plus" style="font-size:40px"></i>
                  </label>
                </div>
          </el-form-item>

          <el-form-item label="其他资质" prop="icp">
            <input
                ref="icpuploads"
                @change="uploadImg('icp', $event)"
                multiple
                type="file" 
                accept="image/*"
                name="upload"
                id="icpuploadFile"
                hidden
                enctype="multipart/form-data"
                >
              
                <div class="img-contaner">
                  <template v-if="icpQuaUrl && icpQuaUrl.length > 0">
                    <div class="img-warrp" v-for="(item, index) in icpQuaUrl" :key="index">
                      <div class="mask">
                          <div class="del" @click="deleteImg(index,'icpQuaUrl')"><i class="el-icon-delete" style="font-size:20px;color:#fff;position:absolute;left:0;top:0;right:0;bottom:0;margin:auto"></i></div>
                      </div>
                      <img :src="item">
                    </div>
                  </template>
                  <label for="icpuploadFile" class="uploa-files" v-if="icpQuaUrl.length < 9">
                      <i class="el-icon-plus" style="font-size:40px"></i>
                  </label>
                </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible4 = false">
          取消
        </el-button>
        <el-button type="primary" @click="quaDateData">
          保存
        </el-button>
      </div>
    </el-dialog>



    <!--信息表单 BEGIN -->
    <el-dialog title="编辑" :visible.sync="dialogFormVisible3">
      <el-form ref="accForm" :model="accountFrom" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">
        <el-form-item label="广告主ID" prop="id">
          <el-input v-model="accountFrom.id" maxlength="25" placeholder="请输入" disabled/>
        </el-form-item>
        <el-form-item label="警告线金额" prop="warningAmount">
          <el-input v-model="accountFrom.warningAmount" maxlength="25" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="账户状态" prop="status">
         <el-switch
            v-model="accountFrom.status"
            @change="changeAccount(accountFrom)"
            :active-value="1"
            :inactive-value="2"
            active-text="开"
            inactive-text="关">
          </el-switch>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible3 = false">
          取消
        </el-button>
        <el-button type="primary" @click="accupdateData">
          保存
        </el-button>
      </div>
    </el-dialog>

    <!--表单 END -->
    <br />
    <div style="background: #fff ">
    <el-table
        ref="singleTable"
        :data="tableData"
        border
        fit
        style="width: 100%;height: 100%"
    >
      <el-table-column
          fixed
          fit
          align="center"
          type="index"
          min-width="20">
      </el-table-column>
      <el-table-column prop="id" label="广告主ID" min-width="40" />
      <el-table-column prop="account" label="广告主账户名称" min-width="40" />
      <el-table-column prop="name" label="广告主渠道名称" min-width="40" />
      <el-table-column prop="balance" label="广告主账户余额" min-width="40">
        <template slot-scope="scope">
          <span>{{scope.row.balance ?  scope.row.balance : '0'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="contractPrice" label="广告主初始合约价" min-width="40">
        <template slot-scope="scope">
          <span>{{scope.row.contractPrice ?  scope.row.contractPrice : '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="warningAmount" label="警告线金额" min-width="40">
         <template slot-scope="scope">
          <span>{{scope.row.warningAmount ?  scope.row.warningAmount : '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="广告主账户余额状态" align="center" min-width="40">
        <template slot-scope="scope">
          <span>{{scope.row.accountStatus == 1 ?  '可用' : scope.row.accountStatus == 2 ? '冻结' : '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="广告主账号状态" align="center" min-width="40">
        <template slot-scope="scope">
          <span>{{scope.row.cp ?  '已启用' : '已禁用'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="广告主创建注册时间" min-width="50" />
      <!-- <el-table-column prop="createTime" label="更新时间" min-width="40" /> -->
       
       <!-- <el-table-column label="广告主账号状态" align="center" min-width="50">
        <template slot-scope="scope">
          <el-switch
            style="display:block;"
            v-model="scope.row.cp"
            @change="change(scope.row,scope.$index)"
            active-text="开"
            inactive-text="关">
          </el-switch>
        </template>
      </el-table-column> -->

      <el-table-column label="操作" align="center" min-width="110" >
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="primary"
              @click="handleQua(scope.$index, scope.row)">资质信息编辑</el-button>
          <el-button
              size="mini"
              type="primary"
              @click="handleUpdate(scope.$index, scope.row)">基础信息编辑</el-button>
          <el-button
              size="mini"
              type="primary"
              :disabled="scope.row.accountStatus == null"
              @click="handleAccountUpdate(scope.$index, scope.row)">账户信息编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination style="background: #fff ;margin-top: 20px;margin-left: 10px"
                   background @current-change="handleCurrentChange" :page-size="listQuery.size" layout="total,prev, pager, next" :total="total">
    </el-pagination>
    <div style="height: 30px;" />

    </div>


  </div>
</template>

<script>
import typesDate from '@/assets/types.json';
import { quaUpdate, adUserCreate, quaAdd, adUserGetList, adUserGetUpdate, adUserAccountupdate, uploadImg, getOne } from '@/api/adUsers';

export default {
  name: "appProfit",
  inject: ['load','closeLoad'],
  components: {  },
  data() {
    const typeNameRules = (rule,value,callback) =>{
        const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if(value !== '') {
            if (!reg.test(value)) {
                callback(new Error('请输入正确的号码'))
            } else {
                callback()
            }
        } else {
            callback(new Error('不能为空'))
        }
    }
    const pwdRules = (rule,value,callback) =>{
        const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,8}$/
        if(value !== '') {
            if (!reg.test(value)) {
                callback(new Error('请输入正确的密码'))
            } else {
                callback()
            }
        } else {
            callback(new Error('不能为空'))
        }
    }
    const upPwdRules = (rule,value,callback) =>{
        const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,8}$/
        if(value) {
            if (!reg.test(value)) {
                callback(new Error('请输入正确的密码'))
            } else {
                callback()
            }
        } else {
            callback()
        }
    }
    const telRules = (rule,value,callback) =>{
        const reg = /^(([0\\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/
        if(value) {
            if (!reg.test(value)) {
                callback(new Error('请输入正确的号码'))
            } else {
                callback()
            }
        } else {
            callback()
        }
    }
    const priceRules = (rule,value,callback) =>{
        if(value) {
            let digit
            if(value.toString().indexOf('.') > -1) {
              digit = value.toString().split('.')[1].length
            }
            if (value <= 0) {
                callback(new Error('请输入正确的标签价格'))
            } else if(digit > 2) {
              callback(new Error('最多保留两位小数'))
            }
            else {
                callback()
            }
        } else {
            callback()
        }
    }
    // const typesRules = (rule,value,callback) =>{
    //     console.log(value)
    //     if(value) {
    //         callback()
    //     } else {
    //         callback(new Error('不能为空'))
    //     }
    // }
    return {
      total: 0,
      listQuery: {
        current: 1,
        size: 10,
        dateYMd:'',
        appId:'',
        name:'',
        type:'',
        categoryId:'',
      },
      switchValue1: false,
      dialogFormVisible: false,
      dialogFormVisible1:false,
      dialogFormVisible3:false,
      dialogFormVisible4:false,
      dialogStatus: '',
      dialogStatus1:'',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      qua:{
        id:'',
        cert:'',
        entQualification:'',
        icp:'',
        types:''
      },
      quaRules:{
        cert: [{ required: true, message: '不能为空', trigger: ["blur",'change'] }]
      },
      cert:[],
      entQuaUrl:[],
      icpQuaUrl:[],
      rules: {
        name: [{ required: true, message: '不能为空', trigger: 'blur' }],
        account: [{ required: true, message: '不能为空', trigger: 'blur' }],
        password: [{ required: true, validator: pwdRules, trigger: 'blur' }],
        phone: [{ required: true, validator: typeNameRules, trigger: 'blur' }],
        tel: [{ required: false, validator: telRules, trigger: 'blur' }],
        contractPrice: [{ required: false, validator: priceRules, trigger: 'blur' }],
        updatepassword: [{ required: false, validator: upPwdRules, trigger: 'blur' }],
        types: [{ required: true, message: '不能为空', trigger: ["blur",'change'] }],
        entType: [{ required: true, message: '不能为空', trigger: ["blur",'change'] }],
        address: [{ required: true, message: '不能为空', trigger: 'blur' }],
        onlineContact: [{ required: true, message: '不能为空', trigger: 'blur' }],
        cert: [{ required: true, message: '不能为空', trigger: ["blur",'change'] }]
      },
      categoryList:[],
      temp: {
        id:'',
        name:'',
        account:'',
        password:'',
        phone:'',
        tel:'',
        contractPrice:'',
        status:'',
        updatepassword:'',
        types:'',
        entType:'',
        address:'',
        onlineContact:'',
      },
      manList:[
        {label:'企业', value:1},
        {label:'个人',value:2}
      ],
      searchForm:{
        name:'',
        account:''
      },
      accountFrom:{
        id:'',
        warningAmount:'',
        status:''
      },
      appName:'',
      tableData: [],
      appOptions:[],
      options:typesDate,
      quaState:false
    }
  },
  created() {
    // this.getList();
    this.getadUserGetList()
  },
  methods:{
    getadUserGetList(data){
      let para = {
        current: this.listQuery.current,
        size: this.listQuery.size, 
        account: data ? data.account : '',
        name:data ? data.name : ''
      }
      adUserGetList(para).then(response => {
          if(response.code === 1) {
            this.closeLoad()
            let datas = response.body.records
            datas.forEach((item)=>{
              item.cp = item.status == 1 ? true : false
            })
            this.tableData = response.body.records
            this.total = response.body.total
          }
      })
    },
    // qappIdChange(val){
    //   this.load()
    //   this.listQuery.current = 1;
    //   this.getCategoryList(val.id)
    // },
    // getTagListAll() {
    //   tagListAll({}).then(response => {
    //     // console.log(response)
    //     if(response.code === 1) {
    //         this.tagListAll = response.body
    //     }
    //   })
    // },

    // qadIdChange(val){
    //   console.info(val)
    //   this.listQuery.current = 1;
    //   this.getList();

    // },
    uploadImg(types, e) {
        console.log(types)
        let files = e.target.files
        let state = true
        for(var i=0; i<files.length; i++) {
          if(files[i].size / 1024 /1024 >= 5) {
            this.$message.error('上传图片大小不能超过5M!')
            state = false
            break;
          }
        }
        if(state) {
            files.forEach((item)=>{
              let formData = new FormData()
                  formData.append('file', item)
                  uploadImg(formData).then((res) => {
                  if (res.code === 1){
                      if(types === 'cert') {
                        this.cert.push(res.body.imageUrl)
                        this.$set(this.qua, 'cert', '1')
                      } else if(types === 'entQualification') {
                        this.entQuaUrl.push(res.body.imageUrl)
                      } else {
                        this.icpQuaUrl.push(res.body.imageUrl)
                      }
                  }
              })
            })
            if(types === 'cert') {
              this.$refs.uploads.value = ''
            } else if(types === 'entQualification') {
              this.$refs.entuploads.value = ''
            } else {
              this.$refs.icpuploads.value = ''
            }
        }
    },

    deleteImg(data,row) {
      // console.log(this[row])
      this[row].splice(data,1)
      if(row === 'cert' && this[row].length <= 0) {
        this.$set(this.qua, 'cert', '')
        this.cert = []
      }
    },

    change(data) {
      this.temp.status = data.status
    },

    changeAccount(data) {
      this.accountFrom.status = data.status
    },
   
    handleCurrentChange(val) {
      this.listQuery.current = val;
    //   this.getList()
    },
    handlesearch() {
      let para = {
        name: this.searchForm.name ? this.searchForm.name : '',
        account: this.searchForm.account ? this.searchForm.account : '',
      }
      this.getadUserGetList(para)
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    handleFoms() {
      this.dialogFormVisible1 = true
      this.resetFomes()
      this.dialogStatus1 = 'create'
      this.$nextTick(() => {
        this.$refs['dataForms'].clearValidate()
      })
    },
    datechange(){
      console.log(this.listQuery.dateYMd);
      // this.dateYMd = this.listQuery.dateYMd
      this.listQuery.current = 1;
    //   this.getList();

    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let para = {
            name: this.temp.name,
            account:this.temp.account,
            password:this.temp.password,
            phone:this.temp.phone,
            tel: this.temp.tel,
            type: this.temp.types,
            entType:this.temp.entType.join(),
            address:this.temp.address,
            onlineContact:this.temp.onlineContact,
            // cert:certImg.substring(0,certImg.length - 1),
            // entQualification: entImg.substring(0,certImg.length - 1),
            // icp:ipcImg.substring(0,certImg.length - 1)
          }
          adUserCreate(para).then(() => {
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '创建成功',
              type: 'success',
              duration: 2000
            })
            this.cert = []
            this.entQuaUrl = []
            this.icpQuaUrl = []
            this.getadUserGetList()
          })
        }
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let para = {
            id: this.temp.id,
            name: this.temp.name,
            account:this.temp.account,
            password:this.temp.updatepassword,
            phone:this.temp.phone,
            tel: this.temp.tel,
            contractPrice: this.temp.contractPrice,
            status: this.temp.status
          }
          adUserGetUpdate(para).then(() => {
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '更新基础信息成功',
              type: 'success',
              duration: 2000
            })
            this.temp.updatepassword = ''
            this.getadUserGetList()
          })
        }
      })
    },

    handleQua(index, row) {
       this.cert = []
       this.entQuaUrl = []
       this.icpQuaUrl = []
       this.qua.types = row.type
       this.qua.id = row.id
       getOne({admId: row.id}).then((res) => {
          if(res.code === 1) {
            if(res.body) {
              this.quaState = true
              if(res.body.cert) {
                this.$set(this.qua, 'cert', '1')
                if(res.body.cert.indexOf(',') > -1) {
                  this.cert = res.body.cert.split(',')
                } else {
                  this.cert = res.body.cert.split()
                }
              } else {
                this.$set(this.qua, 'cert', '')
                this.cert = []
              }
              if(res.body.entQualification) {
                if(res.body.entQualification.indexOf(',') > -1) {
                  this.entQuaUrl = res.body.entQualification.split(',')
                } else {
                  this.entQuaUrl = res.body.entQualification.split()
                }
              }

              if(res.body.icp) {
                if(res.body.icp.indexOf(',') > -1) {
                  this.icpQuaUrl = res.body.icp.split(',')
                } else {
                  this.icpQuaUrl = res.body.icp.split()
                }
              }
            } else {
              this.quaState = false
              this.cert = []
              this.entQuaUrl = []
              this.icpQuaUrl = []
            }
            this.dialogFormVisible4 = true
            this.$nextTick(() => {
              this.$refs['quAdataForm'].clearValidate()
            })
          }
      })
      // this.$nextTick(() => {
      //   this.$refs['quAdataForm'].clearValidate()
      // })
    },

    quaDateData(){
        this.$refs['quAdataForm'].validate((valid) => {
        if (valid) {
          // let certImg = ''
          // let entImg = ''
          // let ipcImg = ''
          // this.cert.forEach((item)=>{
          //   certImg += item + ','
          // })
          // if(this.entQuaUrl && this.entQuaUrl.length > 0) {
          //   this.entQuaUrl.forEach((item)=>{
          //     entImg += item + ','
          //   })
          // }
          // if(this.icpQuaUrl && this.icpQuaUrl.length > 0) {
          //   this.icpQuaUrl.forEach((item)=>{
          //     ipcImg += item + ','
          //   })
          // }
          let para = {
            id: this.qua.id,
            cert:this.cert.join(),
            entQualification: this.entQuaUrl.join(),
            icp:this.icpQuaUrl.join()
          }
          if(!this.quaState) {
            quaAdd(para).then(() => {
              this.dialogFormVisible4 = false
              this.$notify({
                title: 'Success',
                message: '新增资质信息成功',
                type: 'success',
                duration: 2000
              })
              this.getadUserGetList()
            })
          } else {
            quaUpdate(para).then(() => {
              this.dialogFormVisible4 = false
              this.$notify({
                title: 'Success',
                message: '更新资质信息成功',
                type: 'success',
                duration: 2000
              })
              this.getadUserGetList()
            })
          }
        }
      })
    },

    accupdateData() {
      // this.$refs['dataForm'].validate((valid) => {
        // if (valid) {
          console.log(this.accountFrom)
          let para = {
            id: this.accountFrom.id,
            warningAmount: this.accountFrom.warningAmount,
            status:this.accountFrom.status,
          }
          adUserAccountupdate(para).then(() => {
            this.dialogFormVisible3 = false
            this.$notify({
              title: 'Success',
              message: '更新账户信息成功',
              type: 'success',
              duration: 2000
            })
            this.getadUserGetList()
          })
        // }
      // })
    },
    
    // handleDelete(index, row) {
    //   console.log(index, row);
    //   this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     categoryDel(row).then(() => {
    //       this.$message({
    //         type: 'success',
    //         message: '删除成功!'
    //       });
    //       this.getCategoryList()
    //     })

    //   }).catch(() => {});
    // },
    // resetFomes() {
    //   this.fomrs = {
    //     appId: '',
    //     dateYMd: '',
    //     fomrs:''
    //   }
    // },
    resetTemp() {
      this.temp = {
        name:'',
        account:'',
        password:'',
        phone:'',
        tel:''
      }
    },

    handleAccountUpdate(index, row) {
      this.dialogFormVisible3 = true
      this.accountFrom.id = row.id
      this.accountFrom.warningAmount = row.warningAmount
      this.accountFrom.status = row.accountStatus
      this.$nextTick(() => {
        this.$refs['accForm'].clearValidate()
      })
    },

    handleUpdate(index, row) {
      // this.temp = Object.assign({}, row)
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.temp.id = row.id
      this.temp.status = row.status
      this.temp.name = row.name,
      this.temp.account = row.account,
      this.temp.phone = row.phone,
      this.temp.tel = row.tel,
      this.temp.contractPrice = row.contractPrice,
      this.temp.types = row.type
      this.temp.entType = row.entType.split(',')
      this.temp.address = row.address
      this.temp.onlineContact = row.onlineContact
      // getOne({admId: row.id}).then((res) => {
      //     if(res.code === 1) {
      //       console.log(res)
      //     }
      // })
      // this.cert = row.cert ? row.cert.split() : []
      // this.entQuaUrl = row.entQualification ? row.entQualification.splice() : []
      // this.icpQuaUrl = row.icp ? row.icp.splice() : []
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    }
  }
}

</script>

<style scoped>
.uploa-files{
    display:flex;
    width: 80px;
    height: 80px;
    border: 1px solid #ccc;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.img-contaner{
  display: flex;
  flex-wrap: wrap;
  width: 52%;
  justify-content: flex-start;
}
.img-warrp{
    width: 80px;
    height: 80px;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    margin-right: 4px;
}
.img-warrp img{
    width: 100%;
    /* height: 100%;
    transform: scale(1,1); */
}

.img-warrp:hover .mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    text-align: center;
    line-height: 80px;
    animation: opt .2s ease-in;
}

.img-warrp .mask .del {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    margin: auto;
    visibility: hidden;
    /* background: #fff; */
}

.img-warrp:hover .mask .del {
    visibility: visible;
    cursor: pointer;
}

@keyframes opt {
    0%{ opacity: 0; }
    100%{ opacity: 1; }
}
</style>
